import React from "react"
import {graphql, Link, navigate} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import ModalLayout from "../components/layout/modalLayout";
import DefaultHead from "../components/layout/head";

const PageProjectIntro = ({data, pageContext})=>{
    const {lang='fr'} = pageContext
    const {introProjects} = data
    const projects = introProjects.nodes.filter(p=>new RegExp(`${lang}$`).test(p.parent.name));
    const project = projects[~~(Math.random() * projects.length)];
    const {frontmatter={}} = project
    const {intro, images=[], title='', client=''} = frontmatter;
    const _for_ = lang === 'fr' ? 'pour':'for';
    const _intro_label_ = lang === 'fr' ? 'Création et confection textile':'Textile design and manufacture';
    const image = images[intro-1];
    const {image_orientation='portrait'} = frontmatter

    function close(){
        navigate(`/${lang}/selection`)
    }

    return <div className={`project-page project-page--${image_orientation||'portrait'}`} onClick={close} alt={"close"} onKeyPress={close}>
        <button className="project-page__modal-background" onClick={close} alt={"close"} />
        <div className="project-page__side project-page__side--padding">
            <button className={"site-title"} onClick={close}>Atelier Virginie Morel</button>
            <Link to={`/${lang}`} className="site-ornament" />
            <div className="project-page__intro-label">{_intro_label_}</div>
        </div>
        <div className="project-page__side project-page__side--images">
            <div className="project-images">
                <div><GatsbyImage alt={""} image={getImage(image.image)} /></div>
            </div>
        </div>
        <div className="project-page__side project-page__side--details">
            <div className="project-page__details">
                <div className="project-page__infos">
                    <div className="column">{`${title} ${_for_} ${client}`}</div>
                </div>
            </div>
        </div>
    </div>
}

PageProjectIntro.Layout = ModalLayout

export default PageProjectIntro

export const query = graphql`
    query{
        introProjects:allMarkdownRemark(filter: {frontmatter: {intro: {gte: 1}}}) {
            nodes {
                parent {
                    ... on File {
                        name
                    }
                }
                frontmatter {
                    title
                    intro
                    client
                    images {
                        image {
                            childImageSharp {
                                gatsbyImageData(layout: CONSTRAINED, width: 1280, quality: 90)
                            }
                        }
                    }
                }
            }
        }
    }
`

export const Head = DefaultHead